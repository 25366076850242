.root {
  border: 1px solid var(--palette-error-500);
  box-sizing: border-box;
  border-radius: var(--round-corners);
  width: 65px;
  height: 50px;
  position: relative;
  color: var(--palette-error-500);
}

.root:not(:disabled):active {
    background-color: var(--palette-error-500);
    color: var(--palette-text-000);
  }

.open {
  background-color: var(--palette-error-400);
  color: var(--palette-text-000);
}

.readOnly {
  background-color: transparent;
  border-color: #CBD1D2);
  color: #CBD1D2);
}

.readOnly:hover {
    cursor: not-allowed;
  }

.readOnly {
  background-color: transparent;
  border-color: #CBD1D2;
  color: #CBD1D2;
}

.readOnly:hover {
    cursor: not-allowed;
  }

.invert {
  background-color: var(--palette-error-500);
  color: var(--palette-text-000);
  border-color: var(--palette-error-500);
}

.xIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: inherit;
}

.arrowIcon {
  position: absolute;
  left: 75%;
  top: 25%;
  transform: translate(-50%, 25%);
}
