.root {
  flex: 1 1;
  padding: 0 var(--spacing-2);
}
.line {
  border-color: var(--palette-grey-400);
}
.adornments {
  margin-right: var(--spacing-2);
}
.circle {
  margin-top: var(--spacing-2);
  margin-bottom: var(--spacing-2);
}
.highlightedCircle {
  margin-top: var(--spacing-1);
}
.highlighted {
  background-color: #E2FAF7;
  padding: var(--spacing-2);
  padding-top: var(--spacing-1);
  margin-bottom: var(--spacing-2);
}
.commentText {
  color: var(--palette-text-500);
}
.showReplies {
  padding-left: var(--spacing-2);
}
.commentWrapper {
  flex: 1 1;
}
.rejectButton {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: var(--spacing-1);
}
.rejectButton:disabled {
    background-color: var(--palette-error-500) !important;
    opacity: 1 !important;
  }
