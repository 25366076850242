.deleteButton {
  margin-left: auto;
}

.addButton {
  height: 34px;
}

.flairBadgeURLInput {
  margin-right: var(--spacing-3);
}

.flairBadgeNameInput {
  margin-right: var(--spacing-3);
}

.urlTableCell {
  max-width: 250px;
  word-wrap: anywhere;
}

.emptyCustomFlairText {
  padding-left: var(--spacing-3);
}
