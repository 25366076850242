.root {
  border: 2px solid var(--palette-grey-400);
  box-sizing: border-box;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--palette-text-000);
}
.root:not(:disabled):active {
    background-color: var(--palette-primary-600);
  }
.readOnly {
  background-color: transparent;
  border-color: var(--palette-grey-400);
}
.readOnly:hover {
    cursor: not-allowed;
  }
.readOnly {
  background-color: transparent;
  border-color: #CBD1D2;
}
.readOnly:hover {
    cursor: not-allowed;
  }
.checked {
  background-color: var(--palette-primary-600);
  border-color: var(--palette-primary-600);
  color: var(--palette-text-000);
}
.icon {
  font-weight: var(--font-weight-primary-semi-bold);
  color: inherit;
  display: flex;
}
