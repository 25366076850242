.root {
  height: 100%;
}
.anchor {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-2);
  text-transform: uppercase;
  color: var(--palette-text-100);
  height: 100%;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  box-sizing: border-box;
  padding: var(--spacing-3) 0;
  border-bottom: 3px solid transparent;
}
.anchor:hover {
    cursor: pointer;
  }
.anchor > * {
    margin: 0 calc(0.5 * var(--mini-unit)) 0 0;
  }
.anchor > *:last-child {
    margin: 0;
  }
.active {
  font-weight: var(--font-weight-primary-bold);
  border-bottom: 3px solid #419EA7;
  color: #419EA7;
}
.active svg {
  stroke-width: 3;
}
