.commentMain {
  flex-grow: 1;
}

.commentBox {
  border: 1px solid var(--palette-grey-300);
  padding: var(--spacing-3);
  margin-top: var(--spacing-1);
}

.commentContent {
  margin: var(--spacing-2) 0;
}

.commentUsernameButton {
  padding: 0 0 0 var(--spacing-1);
}

.commentUsernameButton span {
    font-size: var(--font-size-3);
    font-weight: var(--font-weight-primary-semi-bold);
    font-family: var(--font-family-primary);
  }

.edited {
  margin-left: var(--spacing-2);
}

.reportUsername {
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-primary-regular);
  font-family: var(--font-family-primary);
}

.label {
  font-size: var(--font-size-1);
  text-transform: uppercase;
}

.storyTitle {
  font-size: var(--font-size-2);
}

.data {
  color: var(--palette-grey-500);
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-primary-regular);
  white-space: pre-wrap;
}

.commentReported {
  padding-top: calc(var(--spacing-1) / 2);
  padding-bottom: calc(var(--spacing-1) / 2);
  padding-right: calc(var(--spacing-1));
  padding-left: calc(var(--spacing-1));

  background-color: var(--palette-error-500);

  border-color: var(--palette-error-500);
  border-style: solid;
  border-radius: 3px;

  color: var(--palette-text-000);

  text-transform: uppercase;

  font-size: var(--font-size-1);
  font-weight: var(--font-weight-primary-regular);
  font-family: var(--font-family-primary);

  margin-right: var(--spacing-1);
}

.commentNotAvailableInStream {
  font-size: var(--font-size-1);
  font-weight: var(--font-weight-primary-regular);
  font-family: var(--font-family-primary);

  color: var(--palette-grey-400);
}
