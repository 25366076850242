.root {
  font-family: var(--font-family-primary);
  font-style: normal;
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-3);
  line-height: 1.45;
  color: var(--palette-text-500);

  word-wrap: break-word;
}
.root b,
  .root strong {
    font-weight: var(--font-weight-primary-semi-bold);
  }
.root i em {
    font-style: italic;
  }
.root blockquote {
    background-color: var(--palette-grey-200);
    padding: var(--mini-unit);
    margin: calc(2 * var(--mini-unit)) 0 calc(2 * var(--mini-unit))
      var(--mini-unit);
    border-radius: var(--round-corners);
  }
.root blockquote::after {
      content: none;
    }
.root blockquote::before {
      content: none;
    }
.root a {
    color: var(--palette-primary-500);
  }
.root a:hover {
      color: var(--palette-primary-700);
    }
.root :global(.coral-rte-spoiler) {
    background-color: var(--palette-text-900);
    transition: background 300ms;
    border-radius: var(--round-corners);
    color: rgba(0, 0, 0, 0);
    cursor: pointer;
  }
.root :global(.coral-rte-spoiler-reveal) {
    opacity: 1;
    color: var(--palette-text-900);
    background-color: transparent;
    cursor: inherit;
  }
.root :global(.coral-rte-sarcasm) {
    font-family: monospace;
  }
