.root {
  font-family: var(--font-family-primary);
}

.reportsLink {
  padding: var(--spacing-3) var(--spacing-7);
  background-color: var(--palette-grey-200);
}

.header {
  background-color: var(--palette-primary-900);
  padding: var(--spacing-3) var(--spacing-7);
  font-family: var(--font-family-secondary);
}

.header .label {
    color: var(--palette-text-000);
    font-weight: var(--font-weight-secondary-bold);
  }

.label {
  font-size: var(--font-size-1);
  text-transform: uppercase;
}

.refID {
  color: var(--palette-text-000);
}

.reportMain {
  background-color: var(--palette-grey-100);
  padding: var(--spacing-5) var(--spacing-7);
  width: 70%;
  min-height: 100vh;
}

.reportMain .label {
    color: var(--palette-grey-500);
    font-weight: var(--font-weight-primary-semi-bold);
  }

.innerMain {
  background-color: var(--palette-background-body);
  padding: var(--spacing-6);
  flex-grow: 1;
}

.decisionButton {
  margin-left: var(--spacing-2);
}

.autoMarginLeft {
  margin-left: auto;
}

.reporterUsernameButton {
  justify-content: left;
  padding: 0;
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-primary-regular);
}

.data {
  color: var(--palette-grey-500);
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-primary-regular);
  white-space: pre-wrap;
}

.decisionWrapper {
  background-color: var(--palette-grey-100);
}

.decisionIcon {
  color: var(--palette-grey-500);
  margin-right: var(--spacing-1);
}
