.root {
  font-weight: var(--font-weight-primary-bold);
  font-family: var(--font-family-primary);
  font-size: var(--font-size-1);
  line-height: 1.33;
  color: #2C7B8C;
  font-style: var(--font-style-underline);
  display: flex;
  align-items: center;
}

.arrow {
  margin-left: var(--spacing-1);
}
